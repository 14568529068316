<template>
  <div id="export">
     <div class="export-box">
        <Else></Else>
       <div class="box-left">
            <div class="left-title">专家目录</div>
            <!-- <div @click="stretch(1)" class="left-side">
                 <i :class="titleshow==isTags(1)? 'el-icon-caret-bottom':'el-icon-caret-right'"></i><span>知识产权专家</span>
            </div>
            <div class="left-ort" v-show="isTags(1)">
                <div v-for="(v,k) in arr1" :key="k"  class="left-name" @click="watch(v.specialistName)">
                    <div class="name1">{{v.specialistName}}</div>
                    <div class="name2">{{v.domain}}</div>
                </div>
            </div> -->
            <div class="left-side">
                 <i class="el-icon-caret-bottom"></i><span>知识产权专家</span>
            </div>
            <div class="left-ort">
                <div v-for="(v,k) in arr1" :key="k"  class="left-name" @click="watch(v.specialistName)">
                    <div class="name1">{{v.specialistName}}</div>
                    <div class="name2">{{v.domain}}</div>
                </div>
            </div>
            <div  class="left-side">
                 <i class="el-icon-caret-bottom"></i><span>品牌培育专家</span>
            </div>
            <div v-for="(item,index) in arr2"  class="left-name" @click="watch(item.specialistName)">
                <div class="name1">{{item.specialistName}}</div>
                <div class="name2">{{item.domain}}</div>
            </div>
            <!-- <div @click="stretch(2)"  class="left-side">
                 <i :class="titleshow==isTags(2)? 'el-icon-caret-bottom':'el-icon-caret-right'"></i><span>品牌培育专家</span>
            </div>
            <div v-for="(item,index) in arr2" v-show="isTags(2)" class="left-name" @click="watch(item.specialistName)">
                <div class="name1">{{item.specialistName}}</div>
                <div class="name2">{{item.domain}}</div>
            </div> -->
       </div>
       <div class="box-right">
            <div class="right-name">{{person.specialistName}}</div>
            <div class="right-zhuan">
                <div>{{person.domain}}</div>
                <div></div>
            </div>
            <div class="right-detail">
                <div class="detail1">
                    <img  :src="person.headPortrait?person.headPortrait:require('/static/images/head1.png')" alt="">
                </div>
                <div>
                    <div><span>出生年月：</span><span>{{person.birthdate}}</span></div>
                    <div><span>技术职称：</span><span>{{person.technicalTitle}}</span></div>
                    <div><span>从业年限：</span><span>{{person.workTime}}</span></div>
                    <div><span>学历：</span><span>{{person.educationBackground}}</span></div>
                </div>
                <div class="detail2">
                    <div><span>工作单位：</span><span>{{person.workUnit}}</span></div> 
                    <!-- <div v-show="secshow"><span>通讯地址：</span><span>{{person.contactAddress}}</span></div> -->
                    <div><span>联系邮箱：</span><span>{{person.mailbox}}</span></div>
                    <div><span>联系方式：</span><span @click="openChatWindow()">在线咨询获取</span></div>
                </div>
            </div>
            <div class="right-middle">
                <span>简历</span>
            </div>
            <div class="right-inst">
              {{person.resume}}
            </div>
           <div class="right-middle">
                <span>领域荣誉</span>
            </div>
            <div class="right-inst">
                <div v-for="item in person.honor" :key="item">
                    {{item}}
                </div>
                
            </div>
       </div>
       
     </div>
     <div style="clear:both;"></div>
  </div>
</template>

<script>
import {getport} from "../../api/api";
import layer from "layui-layer";
import Else from '../ui/else.vue';
export default {
  name: 'export',
  data(){
      return{
        titleshow:true,
        firstid:[2],
        arr1:[],
        arr2:[],
        all:[],
        person:{}
      }
  },
  components: {
      Else
  },
  computed:{
      
  },
  methods:{
      LayerOpen(title, url, width, height) {
			           layer.open({
			                type: 2,
			                title: title,
			                shadeClose: true,
			                shade: 0,
			                offset: "auto",
			                area: [width + 'px', height + 'px'],
			                content: url //iframe的url
			            });
			        },
			       openChatWindow() {
								//  layer.closeAll()
								this.LayerOpen("在线客服", "https://chatlink.mstatik.com/widget/standalone.html?eid=6137f9552f6e2810ce4a5959d7d71084&agentid=dc14ff41af0d0fd424b1013c2726d38b", 690,620);
								_MEIQIA('hidePanel')
						},
    async getData(){
              let res = await getport()
              if(res.data.code === 200){
                for(var i =0;i<res.data.data.length;i++){
                    if(res.data.data[i].cateName == "知识产权专家"){
                        this.arr1 = res.data.data[i].specialist
                    }else{
                        this.arr2 = res.data.data[i].specialist
                    }
                }
                this.all = this.arr1.concat(this.arr2);
               this.person = this.arr2[2];
               this.person.honor= this.person.honor.split("\\n")
               //子向父发送数据
      this.$nextTick(()=>{
        var height =  document.documentElement.scrollHeight;
        console.log(height)
          window.parent.postMessage(
          {
            data: height,
          },
          "*"
        );
      })
              }
          },
          watch(val){
            for(var i = 0;i<this.all.length;i++){
                if(val== this.all[i].specialistName){
                    this.person = this.all[i];
                }
            }
            // console.log(this.person)
            this.person.honor= this.person.honor.split("\\n")
          },
    //  stretch(val) {
    //   if (this.firstid.includes(val)) {
    //     for (var i = 0; i < this.firstid.length; i++) {
    //       if (val == this.firstid[i]) {
    //         this.firstid.splice(i, 1)
    //       }
    //     }
    //   } else {
    //     this.firstid.push(val);
    //   }
    // },
    //  isTags(val) {
    //   if (this.firstid.includes(val)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  mounted(){
    this.getData()
  }
}

</script>

<style scoped>
  #export{
    padding: 50px 0;
    background: #FFFFFF!important;
  }
  .export-box{
    width: 1200px;
    margin: 0 auto;
  }
   .export-box>div{
    float: left;
   }
   .box-left{
    width: 250px;
    background: #DFEAFF; 
    padding-bottom: 40px;
   }
   .left-title{
    width: 250px;
    color:#FFFFFF;
    height: 50px;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    background: url("../../../static/images/sideBg.png") no-repeat;
    background-size: cover;
   }
   .left-side{
    margin: 25px 0 0 10px;
    /* cursor: pointer; */
   }
   .left-side>span{
    color: #2553B2;
     vertical-align: middle;
   }
   .el-icon-caret-right, .el-icon-caret-bottom {
    color: #2253B4;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 8px;
    }
    .left-ort{
        height: 558px;
        overflow-y: auto;
    }
    .left-ort::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.left-ort::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.left-ort::-webkit-scrollbar-track {
  opacity: 0;
}
    .left-name{
        width: 180px;
        padding-bottom: 5px;
        margin-left: 40px;
        margin-top: 15px;
        cursor: pointer;
        border-bottom:1px solid #C9C9C9;
    }
    .name1{
        font-size: 16px;
        margin-bottom: 10px;
        color: #2F2F2F;
    }
    .name2{
        font-size: 14px;
        font-weight: 400;
        color: #898989;
    }
    .box-right{
        width: 900px;
        margin-left: 50px;
    }
    .right-name{
        width: 100%;
        text-align: center;
        font-size: 32px;
        line-height: 64px;
        margin-bottom: 10px;
    }
    .right-zhuan{
        margin-bottom: 25px;
         height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .right-zhuan>div:nth-of-type(1){
        padding: 0 15px;
         height: 34px;
         font-size: 14px;
         line-height: 34px;
        background: #2253B4;
        color: #FFFFFF;
        position: absolute;
       border-radius: 15px;
       z-index: 11;
    }
    .right-zhuan>div:nth-of-type(2){
        width: 100%;
       height: 1px;
       background: #ECEEF1;
       position: absolute;
    }
    .right-detail{
        height: 140px;
    }
    .right-detail>div{
        float: left;
        font-size: 14px;
    }
    .detail1{
        margin: 0 75px;
    }
    .right-detail>div>div{
        margin-bottom: 15px;
    }
    .detail1>img{
        width: 120px;
        height: 120px;
        border-radius: 100%;
    }
    .detail2{
        margin-left: 75px;
    }
    .detail2>div:nth-of-type(3)>span{
        display: inline-block;
    }
    .detail2>div:nth-of-type(3)>span:nth-of-type(2){
        width: 143px;
        height: 21px;
        line-height: 21px;
        cursor: pointer;
        border-radius: 4px;
        text-align: center;
        background: #9BB0DA;
    }
    .right-middle{
        font-size: 18px;
        color: #FFFFFF;
        width: 100%;
        margin: 20px 0 ;
        text-align: center;
        height: 33px;
        line-height: 33px;
        background: url("../../../static/images/tagBg.png") no-repeat;
        background-size: cover;
    }
    .right-middle>span{
        margin-left: 65px; 
    }
    .right-inst{
        text-indent: 28px;
        font-size: 14px;
        line-height: 28px;
        color: #585858;
    }
</style>
